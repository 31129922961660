.product-info {
    margin-top: 5rem !important;
}

.product-info h3{
    font-size: 1.7rem !important;
    font-weight: 700 !important;
}

.product-info h5{
    font-size: 1rem !important;
    font-weight: 600 !important;
    padding: 0.5rem 0;
}

.product-info p{
    font-size: 0.9rem !important;
    font-weight: 500 !important;
}

.highlight-title {
    font-weight: 700;
    font-size: 1.5rem;
}

.additional-text {
    font-weight: 500;
    font-size: 0.9rem;
    text-align: justify;
}

.highlight-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.initial-separator-product-about {
    padding: 3rem 0;
}

@media (max-width: 768px) {
    .highlight-title {
        font-size: 1.5rem;
    }
    .additional-text {
        padding: 1rem 0;
    }
}