.footer {
  background-color: #1b1b1b;
  color: #fff;
}

.footer-title,
.footer-subtitle {
  color: #f5f5f5;
  font-weight: bold;
}

.contact-map-section {
  margin-bottom: 30px;
}

.address-details {
  font-size: 14px;
}

.map-container iframe {
  border-radius: 8px;
}

.quick-contact-section p {
  font-size: 14px;
}

.social-icons {
  display: flex;
  justify-content: end;
  gap: 50px; /* Espaçamento entre ícones */
  margin-top: 20px;
}

.social-icons a {
  color: #2ecc71 !important; /* Cor verde dos ícones */
  font-size: 50px; /* Tamanho dos ícones */
  transition: color 0.3s, transform 0.3s;
}

.social-icons a:hover {
  color: #f5f5f5 !important; /* Cor verde mais escura no hover */
  transform: scale(1.1); /* Aumenta o tamanho no hover */
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 0;
}

.footer-logo img {
  width: 200px;
}

.footer-logo p {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 1rem 1rem;
}

.footer-logo p span {
  display: block;
  margin-bottom: 1rem;
}

.footer-contact-info p {
  font-size: 14px;
}

.footer-contact-info a {
  color: #f2cb84;
}

.contact-map-container {
    margin-top: 30px;
    padding: 20px;
    background-color: #171616;
    border-radius: 8px;
  }
  
  .contact-map-section {
    display: flex;
    align-items: center;
    background-color: #222;
    padding: 0rem;
    margin: 2rem 5rem;
    border-radius: 30px;

  }
  
  .contact-info {
    padding: 5rem 5rem;
    color: #fff;
    border-radius: 8px;
  }

  .eazy-contact span{
    display: block !important;
    margin-top: 1rem;
    font-size: 0.85rem;
    font-weight: 500;
  }
  
  .contact-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #f5f5f5;
  }
  
  .contact-details {
    font-size: 14px;
    line-height: 1.5;
  }
  
  .contact-details a {
    color: #2ecc71;
    text-decoration: none;
  }
  
  .map-container {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0;
  }
  
  .map-iframe {
    height: 22rem !important;
    width: 100% !important;
    border-radius: 0 30px 30px 0 !important;
  }
  
  .title-map{ 
    margin: 3rem 0rem;
    padding: 0 5rem;
    font-weight: 600;
    font-size: 2.4rem;
  }


  @media (max-width: 768px) {
    .footer {
        text-align: center;
        padding: 2rem 1rem; /* Ajusta o padding para dispositivos móveis */
    }

    .footer-title,
    .footer-subtitle {
        font-size: 1.2rem; /* Ajusta o tamanho da fonte */
    }

    .contact-map-section {
        margin: 1rem 0; /* Reduz a margem */
        flex-direction: column;
    }

    .address-details,
    .quick-contact-section p,
    .footer-contact-info p,
    .footer-logo p {
        font-size: 12px; /* Diminui o tamanho do texto */
    }

    .map-container iframe {
        border-radius: 8px;
        width: 100%; /* Garante que o mapa ocupe a largura total */
        height: 15rem; /* Ajusta a altura para telas menores */
    }

    .social-icons {
        justify-content: center;
        gap: 20px; /* Reduz o espaçamento entre ícones */
        margin-top: 10px;
    }

    .social-icons a {
        font-size: 30px; /* Diminui o tamanho dos ícones */
    }

    .footer-bottom {
        flex-direction: column;
        align-items: center;
        gap: 10px; /* Espaçamento entre elementos */
        padding: 2rem 0;
    }

    .footer-logo img {
        width: 150px; /* Reduz o tamanho da logo */
    }

    .contact-map-container,
    .contact-info {
        padding: 10px; /* Reduz o padding interno */
        margin: 1rem 0;
        text-align: center;
    }

    .eazy-contact span {
        font-size: 0.75rem; /* Diminui o tamanho do texto */
    }

    .contact-title {
        font-size: 20px; /* Ajusta o tamanho da fonte do título */
        margin-bottom: 10px;
    }

    .contact-details {
        font-size: 12px; /* Diminui o tamanho do texto dos detalhes */
    }

    .map-iframe {
        height: 15rem !important; /* Ajusta a altura do iframe */
        border-radius: 8px !important;
    }

    .title-map {
        font-size: 1.8rem; /* Reduz o tamanho da fonte do título do mapa */
        margin: 1.5rem 0;
    }
}
