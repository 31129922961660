/* Container principal da FAQ */
.faq-section {
  padding: 20px;
  background-color: #262626; /* Fundo escuro para destacar a seção */
  border-radius: 8px;
  color: #fff;
  margin-top: 20px;
}

.faq-section h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Estilos do acordeão */
.accordion-button {
  background-color: #333; /* Fundo escuro para o botão do acordeão */
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #444; /* Fundo mais claro quando ativo */
}

/* Cor quando o acordeão está ativo */
.accordion-button:not(.collapsed)::after {
    background-image: none !important; 
}

.accordion-item {
  border: 1px solid #444; /* Borda para separar os itens */
  margin-bottom: 10px;
}

.accordion-body {
  background-color: #2b2b2b; /* Fundo do corpo do acordeão */
  color: #ccc;
  font-size: 14px;
  padding: 15px;
}

.accordion-header {
  color: #ccc;
}

.accordion-button::after {
  filter: invert(1); /* Inverte a cor da seta para ficar visível */
}

/* Título de cada pergunta */
.accordion-item .accordion-button {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

/* Estilo das linhas do acordeão */
.accordion-item + .accordion-item {
  border-top: 1px solid #555;
}

.accordion-item:last-child {
  border-bottom: 1px solid #555;
}

@media (max-width: 768px) {
  .faq-section {
    margin-top: 4rem;
  }
}
