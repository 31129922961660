.banner-home {
    background: linear-gradient(135deg, #34A853 0%, #144221 100%);
    min-height: 90vh;
}

.banner-home .banner-title {
    font-weight: 700;
    font-size: 2rem;
    text-align: left;
    color: #242424 !important; 
    text-transform: uppercase;
}

.banner-home .banner-title span {
    color: #25D366;
    font-size: 2rem;
}

.banner-home .banner-description {
    font-weight: 500;
    white-space: pre-line;
    color: #242424 !important; 
    text-align: justify;
    margin-top: 2rem;
    margin-bottom: 3rem;
}


.banner-home-img {
    width: 100%;
    height: auto;
    border-radius: 5px 30px 30px 5px;
}


.banner-container {
    padding: 2rem 4rem;
}

.card-home {
    padding: 4rem 1rem;
    border-radius: 30px;
    margin-top: 3rem;
    margin-bottom: 4rem;
}

.card-home iframe {
    border-radius: 30px;
    margin: 0 3rem;
}

.button-cel-phone {
    white-space: pre-line;
    font-weight: 600;
    font-size: 1rem;
    background-color: #25D366;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0.7rem 2rem !important;
    color: #242424 !important;
    border-radius: 30px;
    border: solid 1px #242424;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}

.button-cel-phone span{
    padding-right: 1rem !important;   
}

.button-cel-phone:hover {
    transform: scale(1.05);
    background-color: #242424;
    color: #25D366 !important;
    border: solid 1px #25D366;
}

.button-cel-phone span:hover {
    color: #25D366 !important;
}


/*PRODUCT BANNER CSS*/

.banner-produto{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 70vh;
}

.banner-produto .title-col {
    background: linear-gradient(180deg, #144221 0%, #34A853 100%);;
    border-radius: 0 50px 50px 0;
    min-height: 89vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner-produto .marcas-container {
    padding-left: 4rem;
    display: flex;
    align-items: center;
    width: 100% !important;
}

.banner-produto .title {
    font-size: 9rem;
    line-height: 89.8%;
    letter-spacing: -5%;
    font-weight: 700;
    display: block;
    padding-left: 5rem;
    padding-right: 3rem;
    text-align: left !important;
}

.banner-produto .page-address {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.banner-produto .page-address p{
    display: inline-block;
    font-weight: 600;
}

.banner-produto .page-address .slug{
   text-transform: uppercase;
}

.banner-produto .page-address .separator {
    padding: 0 3rem;
}

.banner-produto .description-container {
    display: flex;
    padding-left: 1rem;
    align-items: center;
    justify-content: start;
    margin-top: 2rem;
}

.banner-produto .description-container .point {
    padding: 1rem 1rem !important;
    background-color: #25D366;
    display: inline-block;
    border-radius: 50%;
    margin-left: 2rem;
}

.banner-produto .description-container p {
    display: inline-block;
    font-weight: 700 !important;
    padding: 0 1rem;
    width: 70%;
    margin: 0;
}

.banner-produto .cta-col {
    display: flex;
    align-items: end;
    justify-content: end;
    min-height: 89vh;
}

.banner-produto .produto-image {
    left: 25rem;
    top: 6rem;
    position: absolute;
    width: 38.8rem !important;
    height: auto;
}

.banner-produto .cta-card {
    width: 80% !important;
    padding: 1.5rem;
    border-radius: 30px;
}

.banner-produto .cta-title {
    text-align: left;
    font-weight: 700;
    font-size: 1.2rem;
}

.banner-produto .cta-description {
    text-align: justify;
    font-weight: 500;
    font-size: 0.9rem;
    margin: 0;
}

.banner-produto .cta-btn {
    display: inline-flex !important;
    background-color: #25D366;
    border: 1px solid #f5f5f5;
    color: #f5f5f5;
    border-radius: 30px;
    align-items: center;
    justify-content: space-around;
    margin: 0;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    font-weight: 600;
}

.banner-produto .cta-btn:hover {
    background-color: #f5f5f5;
    color: #25D366;
    border: 1px solid #25D366;
}

.banner-produto .cta-btn svg{
    margin-right: 1rem;
}

.banner-produto .produto-image-mobile {
    display: none;
}

@media (max-width: 768px) {

    .banner-home .content-area{
        flex-direction: column-reverse;
    }    
    
    .banner-container {
        padding: 2rem 1rem;
    }

    .card-home {
        padding: 0rem 1rem !important;
    }

    .card-home .content-area {
        padding-top: 2rem !important;
    }

    /* Ajusta a altura e o padding da seção principal */
    .banner-produto {
        min-height: 60vh;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .banner-produto .page-address p{
        font-weight: 700 !important;
        font-size: 1.4rem;
    }

    /* Logo e menu centralizados */
    .logo-container, .menu {
        text-align: center;
        margin-bottom: 1rem;
    }

    .banner-produto .title-col {
        display: flex;
        justify-content: start;
        align-items: center;
        border-radius: 30px;
        position: relative;
        overflow: hidden;
    }

    /* Ajusta o título para um tamanho menor */
    .banner-produto .title {
        z-index: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        text-align: left !important;
        line-height: 10rem;
        padding-top: 1rem;
    }

    /* Ajusta o container de marcas */
    .banner-produto .marcas-container {
        justify-content: center;
        padding-left: 0;
        padding-top: 2rem;
        padding-bottom: 1rem;
    }

    /* Texto abaixo do título ajustado para mobile */

    /* Descrição e ponto ajustados */
    .banner-produto .description-container {
        align-items: center;
        padding-top: 3rem;
        margin-top: 1rem;
        padding-left: 0;
        margin-left: 0;
        justify-content: center;
    }

    .banner-produto .description-container .point {
        margin: 1rem 1rem; 
        margin-bottom: 3rem;
    }

    .banner-produto .description-container .description {
        text-align: left;
        font-size: 1rem;
        font-weight: 600;
        width: 85%;
        margin-bottom: 3rem !important;
    }


    /* Imagem do produto ajustada para centralizar e reduzir o tamanho */
    .banner-produto .produto-image {
        display: none;
    }

    .banner-produto .produto-image-mobile {
        position: absolute;
        left: 9rem;
        top: 13rem;
        z-index: 1;
        display: initial;
    }


    /* Ajusta o card de contato para caber no mobile */
    .banner-produto .cta-col {
        min-height: auto;
        width: 100%;
        margin-top: 2rem;
        overflow-x: hidden;

    }

    .banner-produto .cta-card {
        width: 100% !important;
        padding: 2rem;
        border-radius: 20px;
        text-align: center;
    }

    .banner-produto .cta-title {
        font-size: 1.5rem;
    }

    .banner-produto .cta-card .col-3 {
        display: none;
    }

    .banner-produto .cta-description {
        font-size: 0.9rem;
    }

    .banner-produto .cta-btn {
        width: 100%;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        margin-top: 1rem;
    }

    /* Ícones ajustados */
    .social-icons a {
        font-size: 30px;
        margin: 0 10px;
    }
}
