.primary-bkg {
    background-color: #1E1E1E;
}

.white-text {
    color: #f2f2f2 !important;
}

.green-text {
    color: #34A853 !important;
}