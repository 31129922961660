body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.line {
  background-color: #34A853;
  display: inline-block;
  width: 10% !important;
  height: 0.7rem;
  border-radius: 30px;
}

a, button {
  transition: background-color 0.5s ease-in-out !important;
}