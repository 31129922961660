.parallax-container {
    position: relative;
    overflow: visible;
  }
  
  .sticky-image-col {
    position: sticky;
    top: 1rem;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 2rem;
    padding-right: 0;
  }
  
  .sticky-image {
    width: 80%;
    height: auto;
    object-fit: cover;
  }
  
  .parallax-content-col {
    position: relative;
  }
  
  .parallax-section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    opacity: 0;
    transform: scale(0.5); /* Começa menor */
    transition: opacity 0.5s, transform 0.5s;
  }
  
.parallax-section h2{
    margin-top: 4rem;
    font-size: 1.8rem !important;
    font-weight: 700;
    text-align: left;
    margin-bottom: 2rem;
}

.parallax-section p{
    text-align: justify;
    font-weight: 500;
}

  .parallax-btn {
    margin-top: 20px;
    padding: 10px 20px;
    border: 1px solid #f5f5f5;
    background-color: #25d366;
    color: #fff;
    border-radius: 30px;
    font-weight: 600;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  
  .parallax-btn:hover {
    background-color: #f5f5f5;
    color: #25d366;
    border-color: #25d366;
  }

  .parallax-home-img {
    width: 90%;
    height: auto;
    border-radius: 30px;
}
  
  /* Estilos para dispositivos móveis */
@media (max-width: 768px) {
  .parallax-container {
      padding: 20px; /* Ajusta o espaçamento */
  }

  .sticky-image-col {
      display: none; /* Oculta a coluna da imagem sticky em dispositivos móveis */
  }

  .parallax-content-col {
      padding: 10px;
      text-align: center; /* Centraliza o texto */
  }

  .parallax-section {
      margin-bottom: 20px; /* Ajusta o espaçamento entre seções */
  }

  .parallax-btn {
      font-size: 14px; /* Diminui o tamanho do texto do botão */
      padding: 10px 15px;
      width: 100%;
  }
  
  h2.white-text {
      font-size: 1.8rem !important; /* Reduz o tamanho do título */
  }

  p.white-text {
      font-size: 1.2rem !important;
      text-align: left !important;
  }
}
