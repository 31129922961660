.services-container {
    min-height: 100vh;
}

.services-container {
    padding: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .service-card {
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    position: relative;
    padding-top: 40px;
    margin: 3rem 1rem;
    min-height: 15rem;
    overflow: visible;
    border-radius: 30px;
    min-height: 23rem;
  }

  .service-card .card-body{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
  }

  .icon {
    position: absolute;
    top: -1.7rem;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #2ecc71;
    border: solid 1px #242424;
    color: #fff;
  }

  .icon img{
    width: 40px;
    height: 40px;
  }
  
  .icon-container.inactive {
    background-color: #777; /* Cor para os ícones inativos */
  }
  
  .service-title {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 !important;
    padding-bottom: 1.5rem;
  }
  
  .service-description {
    font-size: 14px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .whatsapp-button {
    margin-top: 1rem;
    background-color: #25d366;
    border: solid 1px #242424;
    color: #fff;
    font-weight: bold;
    box-shadow: none;
    border-radius: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding: 0.5rem 0;
  }
  
  .whatsapp-button:hover {
    background-color: #242424;
    border: solid 1px #1ebe57;
    color: #1ebe57;
  }
  

  @media (max-width: 768px) {
    .services-container {
        padding: 20px; /* Reduz o padding em dispositivos móveis */
    }

    .service-card {
        margin: 2rem 0.5rem; /* Reduz a margem entre os cards */
        padding-top: 20px; /* Reduz o padding superior */
        min-height: 18rem; /* Ajusta a altura mínima do card */
    }

    .icon {
        top: -1.5rem; /* Ajusta a posição do ícone */
        width: 50px;
        height: 50px;
        font-size: 2rem; /* Reduz o tamanho do ícone */
    }

    .icon img{
      width: 30px;
      height: 30px;
    }

    .service-title {
        font-size: 0.9rem; /* Diminui o tamanho do título */
        padding-bottom: 1rem;
    }

    .service-description {
        font-size: 12px; /* Diminui o tamanho do texto */
        margin-bottom: 15px;
    }

    .whatsapp-button {
        font-size: 0.9rem; /* Diminui o tamanho do texto no botão */
        padding: 10px; /* Ajusta o padding do botão */
        gap: 0.5rem; /* Diminui o espaçamento entre ícone e texto */
    }
}
