.cards-container {
    padding: 5rem 0;
}

.redirect-card {
    background: linear-gradient(180deg, #144221 0%, #34A853 100%);
    border-radius: 20px 50px 50px 50px !important;
    min-height: 95vh; 
    color: #fff; /* Texto branco */
    border-radius: 16px; /* Bordas arredondadas */
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .redirect-card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .image-container {
    background-color: transparent; /* Fundo para a imagem */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
  }

  .icon-container {
    background-color: transparent; /* Fundo para a imagem */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem !important;
  }
  
  .icon-container span {
    display: inline-flex !important;
    padding: 3rem !important;
    border-radius: 50% !important;
    background-color: #1E1E1E;
  }

  .card-image {
    max-width: auto;
    height: 179px;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin: 1rem 0;
  }
  
  .card-text {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .card-button {
    background-color: #fff; /* Fundo branco para o botão */
    color: #000; /* Texto preto */
    font-weight: bold;
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    transition: all 0.3s;
  }
  
  .card-button:hover {
    background-color: #34A853; /* Cor de destaque no hover */
    color: #fff;
  }
  

  @media (max-width: 768px) {
    .cards-container {
        padding: 2rem 1rem; /* Reduz o padding para dispositivos móveis */
    }

    .redirect-card {
        min-height: auto; /* Ajusta a altura mínima para caber melhor em dispositivos móveis */
        padding: 2rem 1rem; /* Adiciona padding interno para melhorar o espaçamento */
        border-radius: 16px; /* Mantém as bordas arredondadas */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduz o sombreamento */
    }

    .redirect-card .card-body {
        padding: 1rem; /* Ajusta o padding interno */
        align-items: center;
    }

    .image-container {
        padding-top: 1rem; /* Reduz o padding superior */
    }

    .icon-container {
        padding-top: 0.5rem !important; /* Reduz o padding superior */
    }

    .icon-container span {
        padding: 2rem !important; /* Reduz o padding do ícone */
    }

    .card-image {
        height: 150px; /* Ajusta a altura da imagem */
    }

    .card-title {
        font-size: 16px; /* Diminui o tamanho da fonte do título */
        margin: 0.5rem 0;
    }

    .card-text {
        font-size: 12px; /* Diminui o tamanho da fonte do texto */
        margin-bottom: 15px;
    }

    .card-button {
        font-size: 14px; /* Ajusta o tamanho da fonte do botão */
        padding: 8px; /* Reduz o padding do botão */
    }
}
