.whatsapp-float {
    position: fixed; /* Mantém o botão fixo na tela */
    width: 70px; /* Tamanho do botão */
    height: 70px;
    bottom: 10px; /* Distância do rodapé */
    right: 10px; /* Distância da lateral direita */
    background-color: #25D366; /* Cor do fundo do botão */
    border-radius: 50%; /* Torna o botão circular */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Sombra para destacar o botão */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Mantém o botão à frente de outros elementos */
    transition: transform 0.3s ease; /* Efeito de zoom ao passar o mouse */
  }
  
  .whatsapp-float svg {
    color: #1E1E1E;
  }
  
  .whatsapp-float:hover {
    transform: scale(1.1); /* Aumenta o tamanho ao passar o mouse */
  }
  