.instagram-container {
    padding: 3rem 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.instagram-container h3 {
    padding: 1rem 0;
    padding-bottom: 2rem;
    color: #f5f5f5;
    font-weight: 300;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
}

.highlight {
    margin-left: 0.8rem;
    font-style: italic;
    font-weight: 600;
}

@media (max-width: 768px) {
    .instagram-container {
        padding: 0; /* Reduz o padding para dispositivos móveis */
    }

    .instagram-container h3 {
        font-size: 1.2rem; /* Reduz o tamanho da fonte */
        padding: 2rem 0;
        gap: 10px; /* Diminui o espaçamento entre elementos */
    }

    .highlight {
        margin-left: 0.5rem; /* Reduz a margem à esquerda */
        font-size: 1rem; /* Ajusta o tamanho da fonte */
    }
}
